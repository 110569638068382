.el-textarea .el-textarea__inner {
  min-height: 10px !important;
  height: 100px !important;
  resize: none;
}
.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}
.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}
.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
}
.level {
  padding: 3px;
  border-radius: 6px;
  color: #333;
  background-color: #e0f2ff;
  min-width: 20px;
  text-align: center;
}
.lessonNum {
  display: flex;
  padding: 1rem;
}
.lessonNum span {
  padding: 0 1rem;
}
.lessNumcount {
  display: flex;
  align-items: center;
  padding: 0 2rem;
}
.lessNumcount span {
  width: 10rem;
}
