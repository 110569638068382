








































































  .noStyle {
    position: relative;
    width: 100%;
   
    .bottom-btn {
      position: absolute;
      right:13px;
      top: 13px;
      
    }
  }


.framePage-tabs {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
}
.el-tab-pane {
  display: flex;
  flex: 1;
  overflow: hidden;
}
